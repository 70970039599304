import React from 'react';
import { useUserPasswordChange, useOrgPasswordChange, useMemberPasswordChange } from '../../hooks';

import { UnVerified } from './UnVerified';
import { Verified } from './Verified';

import "./index.css";

export const ChangeUserPassword = () => {
    let { loading, verified, setConfirmNewPassword, setNewPassword, confirm  } = useUserPasswordChange();

    return (
        <div className="ChangePasswordPage">
            { 
                loading ? <></> : !verified ? 
                <Verified setNew={setNewPassword} confirmNew={setConfirmNewPassword} confirm={confirm}/> : 
                <UnVerified /> 
            }
        </div>
    )
}

export const ChangeOrgPassword = () => {
    let { loading, verified, setConfirmNewPassword, setNewPassword, confirm  } = useOrgPasswordChange();

    return (
        <div className="ChangePasswordPage">
            { 
                loading ? <></> : !verified ? 
                <Verified setNew={setNewPassword} confirmNew={setConfirmNewPassword} confirm={confirm}/> : 
                <UnVerified /> 
            }
        </div>
    )
}

export const ChangeMemberPassword = () => {
    let { loading, verified, setConfirmNewPassword, setNewPassword, confirm  } = useMemberPasswordChange();

    return (
        <div className="ChangePasswordPage">
            { 
                loading ? <></> : !verified ? 
                <Verified setNew={setNewPassword} confirmNew={setConfirmNewPassword} confirm={confirm}/> : 
                <UnVerified /> 
            }
        </div>
    )
}

