import './index.scss';

import React from 'react';
import { BsFillArrowRightCircleFill } from "react-icons/bs";

import SignedOut from '../../Components/navbar/SignedOut';
import { useAcceptTeamMemberInvite } from '../../hooks/useAcceptTeamMemberInvite';

export const AcceptTeamMemberInvite: React.FC = () => {
    let { loading, acceptedOrExpired, setNewPassword, setConfirmNewPassword, confirm } = useAcceptTeamMemberInvite();

    return (
        <div className='AcceptTeamMemberInvitePage'>
            <SignedOut />

            <div className="content">
                <img src="/sales-people.png" alt="page-food" className="page-food" />

                <div className="form-container box-shadow">
                    <div className="header">Team Member Invite</div>

                    {
                        acceptedOrExpired ?
                            <div className="sub-header">You were invited to join an organizer team. Confirm your new password and accept team invite.</div>
                        : 
                            <div className="sub-header inactive">Can no longer accept invite from organizer</div>
                    }   

                    {
                        acceptedOrExpired && (
                            <>
                                <div className="label">
                                    <label htmlFor="email">Password</label>
                                    <input type="email" className="form-control-input" name="email" 
                                        onChange={ (e) => setNewPassword( e.target.value ) }
                                    />
                                </div>

                                <div className="label">
                                    <label htmlFor="phone_number">Confirm Password</label>
                                    <input type="telle" className="form-control-input" name="phone_number" 
                                        onChange={ (e) => setConfirmNewPassword( e.target.value ) }
                                    />
                                </div>

                                <div className="submit"
                                    onClick={ confirm }
                                >
                                    <div className="btn-text">Accept Invite</div>
                                    <BsFillArrowRightCircleFill className="btn-ico" />
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
