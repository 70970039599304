import './index.scss';
import './Settings/index.scss';

import React, { useState } from 'react';

import Navbar from '../../Components/navbar/SignedIn';

import PopUp, { AlertProps } from '../../Components/PopUp';

import Profile from './Profile';
import Settings from './Settings';

import { useProfileHome } from '../../hooks/pages/userProfile/useHome';

export const UserHome: React.FC = () => {
    const [ popUp, setPopUp ] = useState<{ activate: boolean;  disablePopUp: () => void; alert: AlertProps }>({
        activate: false,
        disablePopUp: () => setPopUp( prev => ({...prev, activate: false }) ),
        alert: {
            type: 'alert',
            alert: {
                title: ''
            }
        }
    });

    const { loading, token, userFollowing, favoriteOrganizerEvents, eventsFriendsGoingTo, user, slide, setSlide, deleteUserFollowing, orgFollowing } = useProfileHome({ setPopUp });

    return (
        !user ? <></>: (
            <div className='user-home-page'>
                <Navbar profilePicture={user.profile.profilePicture || "/home-header.png" } />
                
                <div className='header'>
                    <img src="/user-home-banner2.png" alt='banner' className='banner' />

                    <div className='profile-header'>

                        <div className='profile-header-content'>
                            <img className='profile-header-img' src={user.profile.profilePicture || "/home-header.png" }  alt='profile' />

                            <div className='name-stats'>
                                <div className='name'>{user.profile.username}</div>

                                <div className='stats'>
                                    <div className='stats-data'>{user.profile.events} events</div>

                                    <div className='bullet' />

                                    <div className='stats-data'>{user.profile.followers} Followers</div>

                                    <div className='bullet' />

                                    <div className='stats-data'>{user.profile.following} Following</div>
                                </div>

                            </div>

                        </div>

                        <div className='buttons-container'>
                            <div className='buttons' onClick={ () => setSlide('profile') }>Profile</div>

                            <div className='buttons' onClick={ () => setSlide('settings') }>Settings</div>
                        </div>
                    </div>

                    <div className='seperator' />
                </div>

                {
                    slide === 'profile' ? 
                    <Profile 
                        organizersFollowing={orgFollowing} 
                        userFollowing={userFollowing} 
                        organizersFollowingEvents={favoriteOrganizerEvents} 
                        usersFollowingEvents={eventsFriendsGoingTo}
                        deleteUserFollowing={deleteUserFollowing}

                        deleteOrgFollowing={
                            async ( id: string ) => {
                                
                            }
                        }
                    />
                    : slide === 'settings' ?
                        <Settings 
                            alertPopUp={ ( val ) => {
                                setPopUp( prev => ({
                                    ...prev, 
                                    activate: true,
                                    alert: { 
                                        ...prev.alert,
                                        alert: {
                                            ...prev.alert.alert,
                                            title: val
                                        }
                                    }
                                }));
                            }}
                        /> : <></>
                }

                <PopUp disableTimer={true} disableLength={20} activate={popUp.activate} disablePopUp={popUp.disablePopUp} type={popUp.alert.type} alert={{
                    title: popUp.alert.alert.title,
                    titleColor: popUp.alert.alert.titleColor,
                    backgroundColor: popUp.alert.alert.backgroundColor
                }} />
            </div>
        )
    )
}
