export const verifyUserPasswordChangeToken = `
query Query($token: String!) {
    verifyUserPasswordChangeToken(token: $token)
}
`;

export const verifyOrganizerPasswordChangeToken = `
query Query($token: String!) {
    verifyOrganizerPasswordChangeToken(token: $token)
}
`;

export const verifyOrganizerMemberPasswordChangeToken = `
query Query($token: String!) {
    verifyOrganizerMemberPasswordChangeToken(token: $token)
}
`;

export const confirmUserPasswordChange = `
mutation confirmUserPasswordChange($token: String!, $newPassword: String!, $confirmNewPassword: String!) {
    confirmUserPasswordChange(confirmNewPassword: $confirmNewPassword, newPassword: $newPassword, token: $token)
}
`;

export const confirmOrgPasswordChange = `
mutation confirmOrgPasswordChange($token: String!, $newPassword: String!, $confirmNewPassword: String!) {
    confirmOrgPasswordChange(confirmNewPassword: $confirmNewPassword, newPassword: $newPassword, token: $token)
}
`;

export const confirmOrganizerMemberPasswordChange = `
mutation confirmOrganizerMemberPasswordChange($token: String!, $newPassword: String!, $confirmNewPassword: String!) {
    confirmOrgPasswordChange(confirmNewPassword: $confirmNewPassword, newPassword: $newPassword, token: $token)
}
`;
