import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LOCAL_STORAGE_NAMESPACES, Craving, fetchGraphQl, organizerLogin, userLogin, reLogin, UserProfileInformation, OrganizerProfileInformation, getOrganizerProfile, getUserProfileInformation } from '../../utils';

export const useLogin = ( craving?: Craving ) => {
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ loggedIn, setLoggedIn ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>("");

    const [ token, setToken ] = useState<string | null>(null);
    const [ tokenType, setTokenType ] = useState<Craving | null>(null);

    const [ user, setUser ] = useState<UserProfileInformation | OrganizerProfileInformation | null >(null);

    /**
     * Login user or organizer, and then saves the token and navigates to home if nav is true.
     * @param email email address for organizer or user
     * @param password password for organizer or user
     * @param craving either a ticket or event, ticket for organizer and event for user
     * @param nav whether or not to go to home page, and save token to local storage
     * @param alarm whether or not to alert when error is encountered
     * @returns 
     */
    const login = async ( email: string, password: string, craving: Craving, nav: boolean = true, alarm: boolean = true ) => {
        setLoading( true );

        let res = await fetchGraphQl( craving === 'ticket' ? organizerLogin : userLogin, { username: email, password } );

        if ( res.errors ) {
            setLoggedIn( false );
            setLoading( false );
            setError( res.errors[0].message );
            const errorMessage = res.errors[0].message;
            const errorElement = document.getElementsByClassName("error-text")[0] as HTMLElement;
            errorElement.innerHTML = errorMessage;
            errorElement.style.display = 'block';
            return errorElement
        }

        let serverToken: string = res.data[ craving === 'ticket' ? 'OrganizerLogIn' : 'UserLogIn'];

        
        // Save locally both the token and type user
        localStorage.setItem( LOCAL_STORAGE_NAMESPACES.TOKEN, serverToken );
        localStorage.setItem( LOCAL_STORAGE_NAMESPACES.CRAVING, craving );
        

        setToken( serverToken );
        setLoggedIn( true );
        setLoading( false );

        if ( nav ) navigate('/home');
    }

    const verifyLogin = async ( nav: boolean = true ) => {
        setLoading( true );

        let token = localStorage.getItem( LOCAL_STORAGE_NAMESPACES.TOKEN );
        let typeUser = localStorage.getItem( LOCAL_STORAGE_NAMESPACES.CRAVING ) as ( Craving | null );
        
        if ( !token || !typeUser ) {
            setLoggedIn( false );
            setLoading( false );
            setError('User has not logged in.');

            if ( nav ) navigate(`/sign-up?${ craving || typeUser ? `craving=${craving || typeUser}` : `` }`);

            return;
        }

        let resUser = await fetchGraphQl( typeUser === 'ticket' ? getOrganizerProfile : getUserProfileInformation, { token } );

        if ( resUser.errors ) {
            setLoggedIn( false );
            setLoading( false );
            setError('User has not logged in.');

            if ( nav ) navigate(`/sign-up?${ craving || typeUser ? `craving=${craving || typeUser}` : `` }`);

            return;
        }

        setUser( resUser.data[ typeUser === 'ticket' ? 'getOrganizerProfile' : 'getUserProfileInformation' ] as OrganizerProfileInformation | UserProfileInformation )
       
        setToken( token );
        setTokenType( typeUser );

        setLoggedIn( true );


        setLoading( false );
    }

    const relogin = async ( nav: boolean = true ) => {
        setLoading( true );

        let token = localStorage.getItem( LOCAL_STORAGE_NAMESPACES.TOKEN );
        let typeUser = localStorage.getItem( LOCAL_STORAGE_NAMESPACES.CRAVING ) as ( Craving | null );
        
        if ( !token || !typeUser ) {
            setLoggedIn( false );
            setLoading( false );
            setError('User has not logged in.');

            if ( nav ) navigate(`/sign-up?${ craving || typeUser ? `craving=${craving || typeUser}` : `` }`);

            return;
        }
        
        let res = await fetchGraphQl(reLogin, { token });

        if ( res.errors ) {
            
            setLoggedIn( false );
            setLoading( false );
            setError('User has not logged in.');

            if ( nav ) navigate(`/sign-up?${ craving || typeUser ? `craving=${craving || typeUser}` : `` }`);

            return;
        }

        localStorage.setItem( LOCAL_STORAGE_NAMESPACES.TOKEN, res.data.relogin );

        let resUser = await fetchGraphQl( typeUser === 'ticket' ? getOrganizerProfile : getUserProfileInformation, { token } );

        if ( resUser.errors ) {
            setLoggedIn( false );
            setLoading( false );
            setError('User has not logged in.');

            if ( nav ) navigate(`/sign-up?${ craving || typeUser ? `craving=${craving || typeUser}` : `` }`);

            return;
        }

        setUser( resUser.data[ typeUser === 'ticket' ? 'getOrganizerProfile' : 'getUserProfileInformation' ] as OrganizerProfileInformation | UserProfileInformation )
       
        setToken( res.data.relogin );
        setTokenType( typeUser );

        setLoggedIn( true );


        setLoading( false );
    }

    return {
        loading,
        loggedIn,
        error,
        token,
        user,
        tokenType,
        login,
        relogin,
        verifyLogin
    }
}
