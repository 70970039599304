export const getOrganizerProfile = `
query GetOrganizerProfile($token: String!) {
    getOrganizerProfile(token: $token) {
      id
      latitude
      location
      longitude
      orgName
      phoneNumber
      profilePicture
      updatedDate
      email
      createdDate
      banner
    }
}
`;

export const verifyOrganizerTeamInviteToken = `
query Query($token: String!) {
  verifyOrganizerTeamInviteToken(token: $token)
}
`;

export const confirmOrganizerTeamMemberAddition = `
query confirmOrganizerTeamMemberAddition($token: String!, $newPassword: String!, $confirmNewPassword: String!) {
  confirmOrganizerTeamMemberAddition(confirmNewPassword: $confirmNewPassword, newPassword: $newPassword, token: $token)
}
`;
