import "./index.scss";
import "./mobile.scss";

import React from "react";
import { useNavigate } from "react-router-dom";

import Markdown from 'markdown-to-jsx';

import { useEvents } from "../../../hooks";
import { formatDate, truncateText } from "../../../utils";

import { isMobile } from 'react-device-detect';

import SignedOutNavBar from "../../../Components/navbar/SignedOut";
import SignedInNavBar from "../../../Components/navbar/SignedIn";
import DownArrow from "../../../Components/svgs/DownArrow";

import { Footer } from "../../../Components/Footer";
import { EventsCard } from '../../../Components/EventCard';

export const Events: React.FC<any> = () => {
    const navigate = useNavigate();

    const { user, loggedIn, tokenType, currentFeaturedEvent, backFeaturedEvent, forwardFeaturedEvent, upComingEvents, popularEvents, popularOrganizerEvents, favoriteOrganizerEvents, eventsFriendsGoingTo } = useEvents();

    return (
        <div className="EventsPage">
            {
                user ? <SignedInNavBar profilePicture={user.profilePicture} /> 
                : <SignedOutNavBar />
            }
            
            <div className="eventContents">
            

                {
                    currentFeaturedEvent && (
                        <div className='featured-event container'>
                            <div className='title'>Featured Event</div>

                            <div className='slide'>
                                <DownArrow className="left" onClick={backFeaturedEvent}/>

                                <div className="content">
                                    <img src={currentFeaturedEvent.banner} alt='event-banner' className='event-banner' />

                                    <div className="info">
                                        <div className='title-cost-date'>
                                            <div className="title">{currentFeaturedEvent.title}</div>
                                        
                                            <div className='loc-url'>
                                                <div className="date cost-range">{formatDate(new Date( currentFeaturedEvent.eventDate ))}</div>

                                                <div className='bullet' />

                                                <div className='cost-range'> {currentFeaturedEvent.costRange} </div>
                                            </div>
                                        </div>

                                        <div className='desc'>
                                            <Markdown>
                                                {truncateText(currentFeaturedEvent.description, 250)}
                                            </Markdown>
                                        </div>

                                        <div className='button' onClick={ () => navigate(`/events/${currentFeaturedEvent.id}`) }>More Info</div>
                                    </div>
                                </div>

                                <DownArrow className="right" onClick={forwardFeaturedEvent} />
                            </div>
                        </div> 
                    )
                }

                <div className='container'>
                    <div className='title'>UPCOMING EVENTS</div>
                    <div className='container-events'>
                    <div className="EventsCard">
                                <div className="extra-card">
                                    
                                    </div><img className="banner" src="https://cravings-media.nyc3.cdn.digitaloceanspaces.com/banner/image/png/cravings-food-fest.png" alt="banner"/>
                                    <div className="title">Cravings Food Fest</div>
                                    <div className="desc"><div><h2 id="event-description">Event Description:</h2><p>Join us for the Cravings Food Festival, a delightful celebration of food and community! Held in the spacious parking lot of Novi High School, this event promises two days filled with mouth-watering flavors, culinary discoverie...</p></div></div>
                                    <div className="loc-url">
                                        <div className="loc-C">
                                        <img src="https://www.cravingsinc.us/food-truck-icon.png" alt="banner"/>
                                        <div className="orgName">CravingsInc</div></div>
                                        <div className="bullet"></div>
                                        <div className="date">07/04/24</div>
                                        <div className="bullet"></div>
                                        <div className="cost-range"> $5-$100 </div>
                                        <div className="bullet"></div>
                                        <svg className="url-ico" width="65" height="40" viewBox="0 0 65 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 15C1.2816e-07 11.0218 3.4166 7.20644 9.49819 4.3934C15.5798 1.58035 23.8282 0 32.4289 0C41.0295 0 49.2779 1.58035 55.3595 4.3934C61.4411 7.20644 64.8577 11.0218 64.8577 15C64.8577 25 32.4289 40 32.4289 40C32.4289 40 0 25 0 15ZM18.9168 15C18.9168 16.6576 20.3404 18.2473 22.8744 19.4194C25.4084 20.5915 28.8452 21.25 32.4289 21.25C36.0125 21.25 39.4493 20.5915 41.9833 19.4194C44.5173 18.2473 45.9409 16.6576 45.9409 15C45.9409 13.3424 44.5173 11.7527 41.9833 10.5806C39.4493 9.40848 36.0125 8.75 32.4289 8.75C28.8452 8.75 25.4084 9.40848 22.8744 10.5806C20.3404 11.7527 18.9168 13.3424 18.9168 15Z" fill="url(#paint0_linear_137_276)"></path><defs><linearGradient id="paint0_linear_137_276" x1="0" y1="0" x2="46.2425" y2="42.0643" gradientUnits="userSpaceOnUse"><stop stop-color="#FF00F3"></stop><stop offset="1" stop-color="#7F74FF"></stop></linearGradient></defs></svg></div></div>

                                <div className="EventsCard">
                                    <div className="extra-card">
                                        <div className="event-passed">EVENT OVER</div>
                                            </div><img className="banner" src="https://cravings-media.nyc3.cdn.digitaloceanspaces.com/banner/image/png/cravings-food-fest.png" alt="banner"/>
                                        <div className="title">Cravings Food Fest</div>
                                        <div className="desc"><div><h2 id="event-description">Event Description:</h2><p>Join us for the Cravings Food Festival, a delightful celebration of food and community! Held in the spacious parking lot of Novi High School, this event promises two days filled with mouth-watering flavors, culinary discoverie...</p></div></div>
                                    <div className="loc-url">
                                        <div className="loc-C">
                                            <img src="https://www.cravingsinc.us/food-truck-icon.png" alt="banner"/>
                                        <div className="orgName">CravingsInc</div></div>
                                        <div className="bullet"></div>
                                        <div className="date">07/04/24</div>
                                        <div className="bullet"></div>
                                        <div className="cost-range"> $5-$100 </div>
                                        <div className="bullet"></div>
                                        <svg className="url-ico" width="65" height="40" viewBox="0 0 65 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 15C1.2816e-07 11.0218 3.4166 7.20644 9.49819 4.3934C15.5798 1.58035 23.8282 0 32.4289 0C41.0295 0 49.2779 1.58035 55.3595 4.3934C61.4411 7.20644 64.8577 11.0218 64.8577 15C64.8577 25 32.4289 40 32.4289 40C32.4289 40 0 25 0 15ZM18.9168 15C18.9168 16.6576 20.3404 18.2473 22.8744 19.4194C25.4084 20.5915 28.8452 21.25 32.4289 21.25C36.0125 21.25 39.4493 20.5915 41.9833 19.4194C44.5173 18.2473 45.9409 16.6576 45.9409 15C45.9409 13.3424 44.5173 11.7527 41.9833 10.5806C39.4493 9.40848 36.0125 8.75 32.4289 8.75C28.8452 8.75 25.4084 9.40848 22.8744 10.5806C20.3404 11.7527 18.9168 13.3424 18.9168 15Z" fill="url(#paint0_linear_137_276)"></path><defs><linearGradient id="paint0_linear_137_276" x1="0" y1="0" x2="46.2425" y2="42.0643" gradientUnits="userSpaceOnUse"><stop stop-color="#FF00F3"></stop><stop offset="1" stop-color="#7F74FF"></stop></linearGradient></defs></svg></div></div>

                                        <div className="EventsCard">
                                <div className="extra-card">
                                    <div className="event-passed">EVENT OVER</div>
                                    </div><img className="banner" src="https://cravings-media.nyc3.cdn.digitaloceanspaces.com/banner/image/png/cravings-food-fest.png" alt="banner"/>
                                    <div className="title">Cravings Food Fest</div>
                                    <div className="desc"><div><h2 id="event-description">Event Description:</h2><p>Join us for the Cravings Food Festival, a delightful celebration of food and community! Held in the spacious parking lot of Novi High School, this event promises two days filled with mouth-watering flavors, culinary discoverie...</p></div></div>
                                    <div className="loc-url">
                                        <div className="loc-C">
                                            <img src="https://www.cravingsinc.us/food-truck-icon.png" alt="banner"/>
                                        <div className="orgName">CravingsInc</div></div>
                                        <div className="bullet"></div>
                                        <div className="date">07/04/24</div>
                                        <div className="bullet"></div>
                                        <div className="cost-range"> $5-$100 </div>
                                        <div className="bullet"></div>
                                        <svg className="url-ico" width="65" height="40" viewBox="0 0 65 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 15C1.2816e-07 11.0218 3.4166 7.20644 9.49819 4.3934C15.5798 1.58035 23.8282 0 32.4289 0C41.0295 0 49.2779 1.58035 55.3595 4.3934C61.4411 7.20644 64.8577 11.0218 64.8577 15C64.8577 25 32.4289 40 32.4289 40C32.4289 40 0 25 0 15ZM18.9168 15C18.9168 16.6576 20.3404 18.2473 22.8744 19.4194C25.4084 20.5915 28.8452 21.25 32.4289 21.25C36.0125 21.25 39.4493 20.5915 41.9833 19.4194C44.5173 18.2473 45.9409 16.6576 45.9409 15C45.9409 13.3424 44.5173 11.7527 41.9833 10.5806C39.4493 9.40848 36.0125 8.75 32.4289 8.75C28.8452 8.75 25.4084 9.40848 22.8744 10.5806C20.3404 11.7527 18.9168 13.3424 18.9168 15Z" fill="url(#paint0_linear_137_276)"></path><defs><linearGradient id="paint0_linear_137_276" x1="0" y1="0" x2="46.2425" y2="42.0643" gradientUnits="userSpaceOnUse"><stop stop-color="#FF00F3"></stop><stop offset="1" stop-color="#7F74FF"></stop></linearGradient></defs></svg></div></div>

                                        <div className="EventsCard">
                                <div className="extra-card">
                                    <div className="event-passed">EVENT OVER</div>
                                    </div><img className="banner" src="https://cravings-media.nyc3.cdn.digitaloceanspaces.com/banner/image/png/cravings-food-fest.png" alt="banner"/>
                                    <div className="title">Cravings Food Fest</div>
                                    <div className="desc"><div><h2 id="event-description">Event Description:</h2><p>Join us for the Cravings Food Festival, a delightful celebration of food and community! Held in the spacious parking lot of Novi High School, this event promises two days filled with mouth-watering flavors, culinary discoverie...</p></div></div>
                                    <div className="loc-url">
                                        <div className="loc-C">
                                            <img src="https://www.cravingsinc.us/food-truck-icon.png" alt="banner"/>
                                        <div className="orgName">CravingsInc</div></div>
                                        <div className="bullet"></div>
                                        <div className="date">07/04/24</div>
                                        <div className="bullet"></div>
                                        <div className="cost-range"> $5-$100 </div>
                                        <div className="bullet"></div>
                                        <svg className="url-ico" width="65" height="40" viewBox="0 0 65 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 15C1.2816e-07 11.0218 3.4166 7.20644 9.49819 4.3934C15.5798 1.58035 23.8282 0 32.4289 0C41.0295 0 49.2779 1.58035 55.3595 4.3934C61.4411 7.20644 64.8577 11.0218 64.8577 15C64.8577 25 32.4289 40 32.4289 40C32.4289 40 0 25 0 15ZM18.9168 15C18.9168 16.6576 20.3404 18.2473 22.8744 19.4194C25.4084 20.5915 28.8452 21.25 32.4289 21.25C36.0125 21.25 39.4493 20.5915 41.9833 19.4194C44.5173 18.2473 45.9409 16.6576 45.9409 15C45.9409 13.3424 44.5173 11.7527 41.9833 10.5806C39.4493 9.40848 36.0125 8.75 32.4289 8.75C28.8452 8.75 25.4084 9.40848 22.8744 10.5806C20.3404 11.7527 18.9168 13.3424 18.9168 15Z" fill="url(#paint0_linear_137_276)"></path><defs><linearGradient id="paint0_linear_137_276" x1="0" y1="0" x2="46.2425" y2="42.0643" gradientUnits="userSpaceOnUse"><stop stop-color="#FF00F3"></stop><stop offset="1" stop-color="#7F74FF"></stop></linearGradient></defs></svg></div></div>
                        {
                            upComingEvents.map( (e, index) => <EventsCard key={index} {...e} /> )
                        }
                    </div>

                    {
                        upComingEvents.length === 0 && (
                           
                            <div className='no-content'>No Current Events Available</div>

                            

                            
                        )
                    }
                </div>

                <div className='container'>
                    <div className='title'>POPULAR EVENTS</div>
                    <div className='container-events'>
                        {
                            popularEvents.map( (e, index) => <EventsCard key={index} {...e} /> )
                        }
                    </div>

                    {
                        popularEvents.length === 0 && (
                            <div className='no-content'>No Current Events Available</div>
                        )
                    }
                </div>

                <div className='container'>
                    <div className='title'>POPULAR ORGANIZERS EVENTS</div>
                    <div className='container-events'>
                        {
                            popularOrganizerEvents.map( (e, index) => <EventsCard key={index} {...e} /> )
                        }
                    </div>

                    {
                        popularOrganizerEvents.length === 0 && (
                            <div className='no-content'>No Current Events Available</div>
                        )
                    }
                </div>

                {
                    loggedIn && tokenType === 'events' && (
                        <div className='container'>
                            <div className='title'>EVENTS BY ORGANIZERS FOLLOWING</div>
                            <div className='container-events'>
                                {
                                    favoriteOrganizerEvents.map( (e, index) => <EventsCard key={index} {...e} /> )
                                }
                            </div>

                            {
                                favoriteOrganizerEvents.length === 0 && (
                                    <div className='no-content'>No Current Events Available</div>
                                )
                            }
                        </div>
                    )
                }
 
                {
                    loggedIn && tokenType === 'events' && (
                        <div className='container'>
                            <div className='title'>EVENTS FRIENDS ARE GOING TO</div>
                            <div className='container-events'>
                                {
                                    eventsFriendsGoingTo.map( (e, index) => <EventsCard key={index} {...e} /> )
                                }
                            </div>

                            {
                                eventsFriendsGoingTo.length === 0 && (
                                    <div className='no-content'>No Current Events Available</div>
                                )
                            }
                        </div>
                    )
                }
            </div>

            <Footer />
        </div>
    )
}
