import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import { fetchGraphQl, verifyOrganizerTeamInviteToken, confirmOrganizerTeamMemberAddition, getOrganizerUrl } from "../utils";

export const useAcceptTeamMemberInvite = () => {
    let { token } = useParams();
    const [ verified, setVerified ] = useState(false);
    const [ loading, setLoading ] = useState(true);

    const [ newPassword, setNewPassword ] = useState("");
    const [ confirmNewPassword, setConfirmNewPassword ] = useState("");

    useEffect( () => {
        (
            async () => {
                let res = await fetchGraphQl( verifyOrganizerTeamInviteToken,  { token });

                setLoading( false );

                if ( res.errors ) return setVerified( false );
                
                console.log( res.data )

                if ( res.data.verifyOrganizerTeamInviteToken === "Token is valid" ) setVerified( true );
                else setVerified( false );
            }
        )()
    });

    return {
        loading,
        acceptedOrExpired: verified,
        setNewPassword,
        setConfirmNewPassword,
        confirm: async () => {
            if ( newPassword.length < 1 || confirmNewPassword.length < 1 ) return alert("Please enter your new password")

            if ( newPassword !== confirmNewPassword ) return alert("Passwords does not match.");
    
            let res = await fetchGraphQl(confirmOrganizerTeamMemberAddition, { token, confirmNewPassword, newPassword });
    
            if ( res.errors ) return alert("Problem accepting invite. Please try again.");
    
            let orgPlatformToken = res.data.confirmOrganizerTeamMemberAddition;
            
            window.location.href = `${getOrganizerUrl()}login/${orgPlatformToken}`
        }
    }
}
