import React from "react";

type PrivateInfoProps = {
    firstName: string;
    setFirstName: (val: string) => void;

    lastName: string;
    setLastName: (val: string) => void;

    phoneNumber: string;
    setPhoneNumber: (val: string) => void;

    saveAllChanges: () => void;
    saveChangesText: string;

    alertPopUp: (val: string) => void;
};

export const PrivateInfo: React.FC<PrivateInfoProps> = ({ firstName, setFirstName, lastName, setLastName, phoneNumber, setPhoneNumber, saveAllChanges, saveChangesText, alertPopUp }) => {
    return (
        <div className='private-info settings-card'>
            <div className='header'>
                <div className='title'>Private Info</div>
            </div>

            <div className="seperator" />

            <div className='content'>
                <div className='container'>
                    <div className="input-container">
                        <div className='title-input'>First Name</div>
                        <input
                            className='input'
                            type='text'
                            placeholder={firstName || "First Name"}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>

                    <div className="input-container">
                        <div className='title-input'>Last Name</div>
                        <input
                            className='input'
                            type='text'
                            placeholder={lastName || "Last Name"}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                </div>

                <div className='container'>
                    <div className="input-container">
                        <div className='title-input'>Phone Number</div>
                        <input
                            className='input'
                            type='text'
                            placeholder={phoneNumber || "( 123 )- 456 - 7890"}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>

                    <div className='save-changes' onClick={saveAllChanges}>{saveChangesText}</div>
                </div>
            </div>
        </div>
    )
}
